/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file
import './scss/frontend.scss';

import infiniteMarquee from 'ohjs-infinite-marquee';

const alertBarPs = document.querySelectorAll('.alert-bar-content p');

if (1 === alertBarPs.length) {
  alertBarPs[0].style.paddingRight = '100vw';
  infiniteMarquee(alertBarPs[0]);
}

import { Collapse } from 'bootstrap';

import './js/swiper.js';

import './js/fancybox.js';

import './js/google.js';

import './js/nice-select.js';

import './js/plyr.js';

import NiceSelect from 'nice-select2';

window.initNiceSelect = function (select) {
  for (let i = 0; i < select.selectedOptions.length; i++) {
    // ensures the starting/default value is shown as selected in the nice-select2 UI
    select.selectedOptions.item(i).setAttribute('selected', '');
  }

  return new NiceSelect(select, {
    searchable: true,
    placeholder: select.placeholder,
  });
};
